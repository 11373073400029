.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../Assests/landing-bg-001.jpg');
  background-size: cover;
  background-position: center;
}

.login-signup-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.form-title {
  font-family: 'Din', sans-serif !important;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.login-custom-input {
  width: 95% !important;
  font-family: 'Din', sans-serif !important;
  font-size: 13px !important;
  padding: 8px;
  border-color: #9d9dff !important;
  border-radius: 5px;
}

.error-message {
  color: red;
  font-family: 'Din', sans-serif !important;
  font-size: 12px;
  text-align: center;
}

.button-name {
  width: 102% !important;
  font-family: 'Din', sans-serif !important;
  font-size: 14px !important;
  padding: 8px;
  border-color: #9d9dff !important;
  border-radius: 5px;
  background-color: rgb(51, 51, 174);
  color: white;
}

.signup-link {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-family: 'Din', sans-serif !important;
  font-size: 14px !important;
}