html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Din', sans-serif;
}

#root {
  height: 100%;
}

.cont-heading {
  font-weight: 400 !important;
  font-family: 'Din', sans-serif !important;
  font-size: 14px;
  color: white !important;
  background: linear-gradient(to right, #5eb5ef, #1e406a);
  padding: 10px;
  border-top-style: solid;
  border-color: white;
  border-top-width: 1px;
}

.custom-iframe {
  width: 100%;
  height: calc(90vh - 48px);
  border: none;
}

.custom-hide {
  background-color: white !important;
  margin-top: -64px !important;
  padding: 2.5vw !important;
  position: relative;
  z-index: 1;
}