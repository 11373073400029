.siderbar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white !important;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.top-cus-logo-section {
  padding: 16px;
  text-align: center;
  background-color: white;
}

.cus-logo {
  width: 100%;
}

.cus-logo-icon {
  width: 100%;
}

.ant-menu-item {
  font-weight: 500 !important;
  font-family: 'Din', sans-serif !important;
  font-size: 12.5px;
  color: #1e406a !important;
}

.ant-menu-item-selected,
.ant-menu-item-active {
  color: #1e406a !important;
}

/* Icon color for menu items */
.ant-menu-item .anticon {
  color: #1e406a !important;
}

.ant-menu-item-selected .anticon,
.ant-menu-item-active .anticon {
  color: #1e406a !important;
}

.header {
  background: linear-gradient(to right, #5eb5ef, #1e406a);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0;
}

.left-buttons {
  display: flex;
  align-items: center;
  margin-left: 4px !important;
}

.right-buttons {
  display: flex;
  align-items: center;
  line-height: 0px !important;
  margin-right: 4px;
}

.header-icon {
  color: white;
}

.header-icon:hover {
  color: #f0f0f0 !important;
}

.side-menu-icon {
  font-size: 18px !important;
}

.cus-username {
  color: white;
  font-size: 13px;
  margin-right: 4px;
}

.cus-sel-menu {
  color: white;
  font-size: 13px;
  margin-left: 6px;
  line-height: 0px !important;
}

.content {
  /* padding: 24px; */
  background: #f0f2f5;
  overflow: auto;
  height: calc(100vh - 48px);
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* .footer {
  background: linear-gradient(to right, #5eb5ef, #1e406a) !important;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 9.3vh;
} */

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 9.3vh;
  padding: 0 20px;
  background: linear-gradient(to right, #5eb5ef, #1e406a) !important;
}

.footer-icon {
  display: flex;
  align-items: center;
  color: white;
  font-size: 22px; 
}
