/* Chat container, fixed at the bottom-right corner */
.chat-with-us-container {
    position: fixed;
    top: 50px;
    right: 10px;
    width: 550px;
    max-width: 100%;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    border-color: #247fd3;
    border-style: solid;
    border-width: 1px;
}

/* Chat header with minimize/close buttons */
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #247fd3; */
    background-color: white;
    /* color: white; */
    color: #247fd3;
    border-color: #247fd3;
    border-bottom-style: solid;
    border-width: 1px;
    padding: 12px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 1rem;
}

.chat-controls {
    display: flex;
    gap: 8px;
}

.minimize-icon,
.close-chat-icon {
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.2s ease-in-out;
}

.minimize-icon:hover,
.close-chat-icon:hover {
    color: #ff6b6b;
}

/* Chat body */
.chat-body {
    padding: 12px;
    flex-grow: 1;
    height: 250px;
    overflow-y: auto;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Chat message styles */
.chat-message {
    padding: 8px 12px;
    border-radius: 12px;
    max-width: 75%;
    word-wrap: break-word;
    font-size: 0.9rem;
    line-height: 1.4;
}

.user-message {
    background-color: #e0f7fa;
    align-self: flex-end;
    text-align: right;
    color: #007b83;
}

.bot-message {
    background-color: #f0f0f0;
    align-self: flex-start;
    text-align: left;
    color: #333;
}

/* Input area for chat messages */
.chat-input {
    display: flex;
    padding: 8px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.chat-input input {
    flex-grow: 1;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 8px 12px;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

.chat-input input:focus {
    border-color: #247fd3;
}

.chat-input button {
    background-color: #247fd3;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-left: 8px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.chat-input button:hover {
    background-color: #1e6bb8;
}

/* Message container */
.message-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    background-color: #f7f7f7;
}

.message-input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 0 8px;
    font-size: 14px;
    background-color: transparent;
}

.send-button {
    color: #1e90ff;
    font-size: 20px;
    cursor: pointer;
    margin-left: 8px;
    transition: color 0.3s ease;
}

.send-button:hover {
    color: #0056b3;
}